import React, {useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import {FaArrowDown} from "react-icons/fa"

const History = () => {
    const timelines = [
        {
            "year": "2017",
            "data": [
                {
                    "title": "Founder",
                    "content": "H.E. Justin Sun founds TRON as an Ethereum Layer2 blockchain with steadfast commitment to decentralization and empowerment. "
                },
                {
                    "title": "Foundation",
                    "content": "The TRON Foundation is established in Singapore and launches what would later become TRX, the native currency of the TRON Network. "
                },
            ]
        },
        {
            "year": "2018",
            "data": [
                {
                    "title": "TestNet",
                    "content": "The TestNet, Blockchain Explorer, and Web Wallet are all launched, and developers can now build, deploy, and test decentralized applications. "
                },
                {
                    "title": "MainNet",
                    "content": "TRON Mainnet launches and shortly afterward transitions from being a Layer2 to becoming an independent blockchain and peer-to-peer cryptocurrency network. "
                },
                {
                    "title": "BitTorrent",
                    "content": "The TRON Foundation acquires BitTorrent, a peer-to-peer file sharing protocol, that today serves as a multi-chain bridge between Ethereum, Binance Smart Chain, and TRON. "
                },
                {
                    "title": "Samsung",
                    "content": "TRON officially partners with Samsung."
                },
                {
                    "title": "TVM",
                    "content": "TRON launches its virtual machine (TVM), which allows developers to write smart contracts on the TRON blockchain."
                },
            ]
        },
        {
            "year": "2019",
            "data": [
                {
                    "title": "Market Cap",
                    "content": "TRON tops $1.6 billion in total market cap. "
                },
                {
                    "title": "BTTC",
                    "content": "BitTorrent launches its own token (now <a target='_blank' href='https://bt.io/'>BTTC</a>) on the TRON Network."
                },
                {
                    "title": "TRONSCAN",
                    "content": "TRON acquires blockchain.org, a blockchain explorer, to enhance transparency and accountability, which became <a target='_blank' href='https://tronscan.org/#/'>TRONSCAN.org</a>."
                },
                {
                    "title": "Tether",
                    "content": "TRON partners with Tether, a stablecoin pegged to the US dollar, and launches a TRC-20 version of Tether on the TRON blockchain."
                },
                {
                    "title": "Poloniex",
                    "content": "TRON acquires <a target='_blank' href='https://poloniex.com/'>Poloniex</a>, a cryptocurrency exchange. "
                },
            ]
        },
        {
            "year": "2020",
            "data": [
                {
                    "title": "Sun Network",
                    "content": "TRON launches a sidechain solution, <a target='_blank' href='https://tron.network/sunnetwork/#/'>Sun Network</a>, aimed at increasing transaction speed and scalability on the TRON blockchain."
                },
                {
                    "title": "Steemit",
                    "content": "TRON acquires <a target='_blank' href='https://steemit.com/'>Steemit</a>, a blockchain-based social media platform."
                },
                {
                    "title": "JustSwap",
                    "content": "TRON launches the <a target='_blank' href='https://just.network/#/'>JustSwap</a> decentralized exchange (DEX), allowing users to trade cryptocurrencies on the TRON blockchain without intermediaries."
                },
                {
                    "title": "Band Protocol",
                    "content": "TRON partners with Band Protocol, a decentralized oracle platform, to enhance the security and reliability of smart contracts on the TRON blockchain."
                },
            ]
        },
        {
            "year": "2021",
            "data": [
                {
                    "title": "Valkyrie",
                    "content": "Valkyrie launches “VTRON” TRON Trust in the US & “VTRX” TRON ETN in 14 EU countries."
                },
                {
                    "title": "NFTs",
                    "content": "<a target='_blank' href='https://apenft.io/'>APENFT Marketplace</a> and Foundation launches on the TRON blockchain, with a collection including artworks by Picasso and Andy Warhol as well as digital assets from crypto artists Beeple, Pak and others, along with other high-end NFT collectibles."
                },
                {
                    "title": "TRON 7.0",
                    "content": "TRON launches TRON 7.0, enhancing the performance and security of the TRON blockchain. "
                },
                {
                    "title": "BitTorrent Chain",
                    "content": "TRON-based, cross-chain protocol BitTorrent Chain (BTTC) goes live. "
                },
                {
                    "title": "DAO",
                    "content": "TRON becomes TRON DAO, a “decentralized autonomous organization,” becoming one of the world’s largest community-governed, public blockchains with a diverse and growing ecosystem."
                },
            ]
        },
        {
            "year": "2022",
            "data": [
                {
                    "title": "Hackathon",
                    "content": "Season 1 of the TRON Grand <a target='_blank' href='https://trondao.org/hackatron/'>Hackathon</a> kicks off followed by Seasons 2 and 3 by year end. The “HackaTRON” has over 2300 total participants in 2022 with a combined total prize pool of over $2.7 million. "
                },
                {
                    "title": "# 1",
                    "content": "TRON overtakes Ethereum as the blockchain with the largest circulating supply of the USDT (Tether) stablecoin, boasting over 50% of total USDT circulation."
                },
                {
                    "title": "TDR",
                    "content": "<a target='_blank' href='https://tdr.org/#/'>TRON DAO Reserve</a> is established to safeguard the overall blockchain industry and crypto market. "
                },
                {
                    "title": "USDD",
                    "content": "The over-collateralized decentralized stablecoin, <a target='_blank' href='https://usdd.io/#/'>USDD</a>, launches on the TRON blockchain, backed by the first-ever crypto reserve for the blockchain industry – TRON DAO Reserve, marking TRON's official entry into stablecoins."
                },
                {
                    "title": "TVL #3",
                    "content": "TRON becomes 3rd largest blockchain by TVL behind Ethereum and Binance Smart Chain, according to Defi Llama."
                },
                {
                    "title": "Eco-Friendly",
                    "content": "In the report “<a target='_blank' href='https://carbon-ratings.com/dl/tron-report-2022'>Energy Efficiency and Carbon Footprint of the TRON Blockchain</a>” released by the Crypto Carbon Ratings Institute (CCRI), TRON is rated as one of the most environmentally blockchains. "
                },
                {
                    "title": "Wintermute",
                    "content": "Wintermute becomes TRON’s official market maker and strategic partner of the entire TRON ecosystem."
                },
                {
                    "title": "TRONpedia",
                    "content": "<a target='_blank' href='https://trondao.org/tronpedia/'>TRONpedia</a>, an encyclopedia-like resource, launches with seven categories of articles: Crypto, Blockchain, DeFi, Web3, NFTs, Stablecoins, and GameFi. "
                },
                {
                    "title": "TVL #2",
                    "content": "<a target='_blank' href='https://defillama.com/chains'>According to DefiLlama</a>, by the end of Q3, TRON surpasses Binance Smart Chain in Total Value Locked (TVL), ranking TRON second among all blockchains in that metric. "
                },
                {
                    "title": "Dominica",
                    "content": "The Commonwealth of <a target='_blank' href='https://dominica.gov.dm/images/TRON_October_07.pdf'>Dominca</a> designates TRON as their national blockchain, marking the first time a major public blockchain partners with a sovereign nation to develop its DeFi infrastructure. On top of the government’s endorsement to issue Dominica Coin (“DMC”), a blockchain-based tourism token to help promote Dominica’s global fanfare, seven existing TRON-based tokens – TRX, BTT, NFT, JST, USDD, USDT, TUSD – are approved as legal tender in the country."
                },
                {
                    "title": "EWI",
                    "content": "The Binance CMC Crypto <a target='_blank' href='https://www.binance.com/en/ew-index'>Equal Weighted Index (EWI)</a>, which tracks the performance of the top ten digital assets on CoinMarketCap as ranked by market capitalization, lists TRON in its top ten."
                },
                {
                    "title": "Academy",
                    "content": "<a target='_blank' href='https://trondao.org/academy/'>TRON Academy</a> officially launches when 270+ students from top-tier university blockchain clubs gathered on the Harvard University campus for the first “Hacker House” HackaTRON event. "
                },
                {
                    "title": "EEA",
                    "content": "TRON joins the <a target='_blank' href='https://entethalliance.org/'>Enterprise Ethereum Alliance</a> to advance enterprise scalability of interoperable, blockchain integration in order to empower companies in their daily business operations. "
                },
            ]
        },
        {
            "year": "2023",
            "data": [
                {
                    "title": "Accounts",
                    "content": "TRON tops 147.5 million total user accounts on the blockchain."
                },
                {
                    "title": "Transactions",
                    "content": "TRON tops 5 billion total transactions on its blockchain. "
                },
                {
                    "title": "TVL",
                    "content": "TRON tops $11.5 billion in total value locked, as reported on TRONSCAN."
                },
                {
                    "title": "Climate",
                    "content": "The <a target='_blank' href='https://trondao.org/ecosystem-climate/'>TRON Climate Initiative</a> launches in January, leading the charge for a sustainable future in blockchain."
                },
                {
                    "title": "A.I.",
                    "content": "TRON partners with Oraichain to integrate artificial intelligence into blockchain development."
                },
                {
                    "title": "Digital Identity",
                    "content": "In collaboration with Huobi and TRON, The Commonwealth of Dominica’s DMC Labs partners with TRON to develop the first ever blockchain-based DID, Dominica Digital Identity (DDID), which is to be used as a unique digital ID in the upcoming Dominica Metaverse. "
                },
                {
                    "title": "Developers",
                    "content": "TRON works strategically to attract more developers to #BUIDLtheFUTURE of decentralized commerce and community, including the kick-off of Season 4 of the “<a target='_blank' href='https://trondao.org/hackatron/'>HackaTRON</a>.”"
                },
                {
                    "title": "Huobi",
                    "content": "In a strategic pivot to prepare for the next major phase of cryptocurrency adoption, TRON collaborates closely with Huobi to focus on commerce and community in Hong Kong and its surounding region, ultimately aiming together to become the premier global crypto ecosystem. "
                },
                {
                    "title": "Every Human on the Planet",
                    "content": "TRON exists to empower decentralized commerce and community for every human on the planet."
                },
            ]
        },
        {
            "year": "2027",
            "data": [
                {
                    "title": "What’s next?",
                    "content": "Come #BUIDLtheFUTURE with us, and make TRON the way the world spends, receives, and settles money."
                },
            ]
        },
    ]

    const [filteredTimelines, setTimelines] = useState(timelines.slice(0, 3))
    const [isButtonClicked, setIsButtonClicked] = useState(false)

    const showRemaining = () => {
        setIsButtonClicked(!isButtonClicked)
        setTimelines(timelines)
    }
    useEffect(()=>{
        window.location.replace('/')
    },[])
    return <></>
    // return (
    //     <div className="trondao-history">
    //         <Helmet>
    //             <title>TRON History | TRONDAO</title>
    //             <meta name="description"
    //                   content="No Description"/>
    //             <meta property="og:description" content="No Description"/>
    //             <meta property="og:title" content="TRON History | TRONDAO"/>
    //         </Helmet>

    //         <div className="history">
    //             <div className="history-main">
    //                 <div className="history-main-title col-5 col-lg-3 d-flex flex-column align-items-start">
    //                     <h1 className="text-uppercase">Tron History</h1>
    //                     <p>
    //                         [ Founded September, 2017 ]
    //                     </p>
    //                 </div>
    //             </div>
    //             <div className="history-body">
    //                 <div
    //                     className="history-body-line col-10 offset-2 col-md-8 offset-lg-0 mx-lg-auto d-flex flex-column">
    //                     {
    //                         filteredTimelines.map((timeline, key) => {
    //                             return (
    //                                 <div key={key} className="history-body-timeline">
    //                                     <div className="history-body-timeline-year">
    //                                         <h5>{timeline.year}</h5>
    //                                     </div>
    //                                     {
    //                                         timeline.data.map((item, index) => {
    //                                             return (
    //                                                 <div key={index}
    //                                                      className="history-body-timeline-details d-flex flex-column flex-lg-row flex-wrap flex-lg-nowrap">
    //                                                     <div
    //                                                         className="history-body-timeline-date d-flex flex-row flex-lg-column justify-content-start justify-content-lg-center align-items-start align-items-lg-center">
    //                                                         <div className="ml-3 ml-lg-0">
    //                                                             {/* <h2>Date Here</h2> */}
    //                                                         </div>
    //                                                         <hr className={key === 0 ? 'outline' : ''}/>
    //                                                     </div>
    //                                                     <div className="history-body-timeline-content">
    //                                                         <div
    //                                                             className="history-body-timeline-info d-flex flex-column flex-lg-row">
    //                                                             <div
    //                                                                 className="history-body-timeline-info-details d-flex flex-column align-items-start justify-content-center px-4 py-2">
    //                                                                 <h2>{item.title}</h2>
    //                                                                 <hr/>
    //                                                                 <p dangerouslySetInnerHTML = {{ __html: item.content}}></p>
    //                                                             </div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             )
    //                                         })
    //                                     }
    //                                 </div>
    //                             )
    //                         })
    //                     }
    //                     {
    //                         !isButtonClicked &&
    //                         <button type="button" onClick={showRemaining} className="cta-button text-uppercase mx-auto">
    //                             More Events <span className="ml-1"><FaArrowDown/></span>
    //                         </button>
    //                     }
    //                     <div className="history-body-bottom-margin"></div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // )
}

export default History
